import http from 'hub-http/clients/apiClient';
import { useQuery, registerQuery } from 'data-fetching-client';
import { useCampaignsContext } from '../CampaignsProvider';
import { SENTRY_KEYS } from 'campaigns-lib/constants/sentry';
import Raven from 'raven-js';
export const GET_ASSOCIATED_CAMPAIGN_FIELD_NAME = 'campaignsPlatformComponentsAssociatedCampaign';
const GET_ASSOCIATED_CAMPAIGN = registerQuery({
  fieldName: GET_ASSOCIATED_CAMPAIGN_FIELD_NAME,
  args: ['objectType', 'assetId', 'crmIdProperty', 'associationSettings'],
  fetcher({
    objectType,
    assetId,
    crmIdProperty,
    associationSettings
  }) {
    const objectSettings = associationSettings[objectType].campaignsAssociations.metadata;
    return http.post(`/crm-search/search`, {
      data: {
        count: 1,
        offset: 0,
        objectTypeId: objectType,
        requestOptions: {
          allPropertiesFetchMode: 'latest_version',
          includeAllProperties: true
        },
        filterGroups: [{
          filters: [{
            operator: 'EQ',
            property: crmIdProperty,
            value: assetId
          }]
        }],
        // Fetch the associated campaigns crm object to this asset via the association id
        associationPreviews: {
          [`0-${objectSettings.associationId.value}`]: {
            includeAllProperties: true,
            allPropertiesFetchMode: 'latest_version'
          }
        }
      }
    }).then(response => {
      var _response$results, _response$results2;
      return {
        campaign: (_response$results = response.results) === null || _response$results === void 0 || (_response$results = _response$results[0].associatedObjects) === null || _response$results === void 0 || (_response$results = _response$results[`0-${objectSettings.associationId.value}`]) === null || _response$results === void 0 ? void 0 : _response$results[0],
        asset: (_response$results2 = response.results) === null || _response$results2 === void 0 ? void 0 : _response$results2[0]
      };
    });
  }
});
export function useFetchAssociatedCampaign({
  objectType,
  assetId,
  crmIdProperty,
  associationSettings,
  onCompleted = () => {}
}) {
  const {
    setInitialCampaign
  } = useCampaignsContext();
  const {
    data,
    loading
  } = useQuery(GET_ASSOCIATED_CAMPAIGN, {
    variables: {
      objectType,
      assetId: assetId,
      crmIdProperty,
      associationSettings: associationSettings
    },
    onError: error => Raven.captureException(error, {
      fingerprint: [SENTRY_KEYS.FRAMEWORK_APP_SETTINGS_FETCH_FAILED],
      tags: {
        lib: 'campaigns-lib'
      }
    }),
    onCompleted: completedData => {
      var _initialCampaign$camp, _initialCampaign$camp2;
      const initialCampaign = completedData === null || completedData === void 0 ? void 0 : completedData[GET_ASSOCIATED_CAMPAIGN_FIELD_NAME];

      // Save the initial campaign to the context to be saved outside of the select.
      setInitialCampaign({
        campaignGuid: initialCampaign === null || initialCampaign === void 0 || (_initialCampaign$camp = initialCampaign.campaign) === null || _initialCampaign$camp === void 0 ? void 0 : _initialCampaign$camp.properties.hs_origin_asset_id.value,
        objectId: initialCampaign === null || initialCampaign === void 0 || (_initialCampaign$camp2 = initialCampaign.campaign) === null || _initialCampaign$camp2 === void 0 ? void 0 : _initialCampaign$camp2.objectId
      });

      // Call the onCompleted callback with the initial campaign to show in the select.
      onCompleted(initialCampaign);
    },
    skip: !assetId || !(associationSettings !== null && associationSettings !== void 0 && associationSettings[objectType])
  });
  return {
    data: data === null || data === void 0 ? void 0 : data[GET_ASSOCIATED_CAMPAIGN_FIELD_NAME],
    loading
  };
}