import { useCallback } from 'react';
import { saveCampaign } from '../api/CampaignsAssociation';
import { useCampaignsContext } from '../CampaignsProvider';
import { SENTRY_KEYS } from 'campaigns-lib/constants/sentry';
import Raven from 'raven-js';
import { useDataFetchingClient } from 'data-fetching-client';
import { GET_ASSOCIATED_CAMPAIGN_FIELD_NAME } from './useFetchAssociatedCampaign';
export function useHandleCampaignChange({
  objectType,
  onChange = () => {},
  save = true,
  selectedCampaignGuid,
  selectedCampaignObjectId,
  selectedAssetCrmId,
  setSelectedCampaignGuid,
  onSuccess = () => {},
  onFailure = () => {}
}) {
  const {
    setUpdatedCampaign
  } = useCampaignsContext();
  const client = useDataFetchingClient();
  return useCallback(({
    target: {
      value: updatedCampaignGuid
    }
  }, selectOption) => {
    if (save && selectedAssetCrmId) {
      saveCampaign({
        objectType,
        selectedAssetCrmId,
        currentCampaignGuid: selectedCampaignGuid,
        currentCampaignObjectId: selectedCampaignObjectId,
        updatedCampaignGuid: updatedCampaignGuid,
        updatedCampaignObjectId: selectOption.objectId || undefined,
        onSuccess,
        onFailure
      }).then(() => {
        client.cache.evict({
          fieldName: GET_ASSOCIATED_CAMPAIGN_FIELD_NAME
        });
      }).catch(error => {
        Raven.captureException(error, {
          fingerprint: [SENTRY_KEYS.CAMPAIGNS_PLATFORM_UPDATE_FAILED],
          tags: {
            lib: 'campaigns-lib'
          }
        });
      });
    }

    // Save the updated campaign to the context to be saved outside of the select.
    setUpdatedCampaign({
      campaignGuid: updatedCampaignGuid === '' ? undefined : updatedCampaignGuid,
      objectId: selectOption.objectId || null
    });

    // Update the selected campaign guid in the select.
    setSelectedCampaignGuid(updatedCampaignGuid === '' ? undefined : updatedCampaignGuid);

    // Invoke the select callback prop for consumers to use.
    onChange === null || onChange === void 0 || onChange(selectOption.guid || null);
  }, [client.cache, objectType, onChange, onFailure, onSuccess, save, selectedAssetCrmId, selectedCampaignGuid, selectedCampaignObjectId, setSelectedCampaignGuid, setUpdatedCampaign]);
}
export default useHandleCampaignChange;