// @ts-expect-error Unfortunately, atom isn't typed
import { deref, watch, unwatch } from 'atom';
import { useEffect, useState } from 'react';
export function useAtomValue(currentAtom) {
  const [atomState, setAtomState] = useState(deref(currentAtom));
  useEffect(() => {
    const listen = nextAtomState => {
      setAtomState(nextAtomState);
    };
    watch(currentAtom, listen);
    return () => {
      unwatch(currentAtom, listen);
    };
  }, [currentAtom]);
  return atomState;
}
export function getAtomValue(currentAtom) {
  return deref(currentAtom);
}