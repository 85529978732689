import styled from 'styled-components';
import { remCalc } from 'UIComponents/core/Style';
import { KOALA, BATTLESHIP } from 'HubStyleTokens/colors';
const LoadingSkeleton = styled.div.attrs({
  'data-loading': 'true'
}).withConfig({
  displayName: "LoadingSkeleton",
  componentId: "sc-1tx1omv-0"
})(["@keyframes pulse{from{background-color:", ";}to{background-color:", ";}}background-color:", ";border-radius:4px;height:", ";margin:", ";margin-top:", ";margin-bottom:", ";width:", ";max-width:", ";animation:2s ease-in-out 0s infinite alternate both running pulse;@media (prefers-reduced-motion){animation:none;}"], KOALA, BATTLESHIP, KOALA, ({
  height
}) => !!height && remCalc(`${height}`), ({
  margin
}) => !!margin && margin, ({
  marginTop
}) => !!marginTop && remCalc(`${marginTop}`), ({
  marginBottom
}) => !!marginBottom && remCalc(`${marginBottom}`), ({
  width
}) => {
  if (!width) {
    return undefined;
  }
  return `${width}`.endsWith('%') ? width : remCalc(`${width}`);
}, ({
  maxWidth
}) => !!maxWidth && remCalc(`${maxWidth}`));
LoadingSkeleton.defaultProps = {
  marginBottom: '2px',
  marginTop: '2px',
  height: '22px',
  width: '100%'
};
export default LoadingSkeleton;