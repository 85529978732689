// @ts-expect-error untyped
import { atom, swap, watch, unwatch } from 'atom';
import { getAtomValue } from '../data/atomUtils';
export const CLEAR_INTERNAL_ACTIVE_EDTIOR_EVENT_NAME = 'clearInternalActiveEditorAtom';
const ClearActiveEditorAtomEvent = new CustomEvent(CLEAR_INTERNAL_ACTIVE_EDTIOR_EVENT_NAME);
export const internalActiveEditorAtom = atom(null);
export function getInternalActiveEditorAtom() {
  return getAtomValue(internalActiveEditorAtom);
}
export function setInternalActiveEditorAtom(editor) {
  swap(internalActiveEditorAtom, () => editor);
}
export function clearInternalActiveEditor() {
  // Note dispatching an event instead of directly setting the atom value to keep
  // things decoupled and prevent apps using clearInternalActiveEditor() to pull in
  // all of tinymce into their main payload.
  document.dispatchEvent(ClearActiveEditorAtomEvent);
}
export function subscribeToActiveEditorAtom(cb) {
  watch(internalActiveEditorAtom, cb);
  return () => {
    unwatch(internalActiveEditorAtom, cb);
  };
}