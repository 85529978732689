import styled, { css } from 'styled-components';
const ALIGN_ICON_TO_TEXT_POSITION = '-3px';
const ICON_SIZE = '30px';
const ENLARGE_ICON_SPACING = '12px';
const iconMixin = css(["position:relative;margin-top:", ";cursor:pointer;&::after{display:block;transition:background 150ms ease-out;border-radius:100%;content:' ';left:50%;width:", ";height:", ";position:absolute;top:50%;transform:translate(-50%,-50%);}&:hover::after{background:rgba(255,255,255,0.1);}"], ALIGN_ICON_TO_TEXT_POSITION, ICON_SIZE, ICON_SIZE);
const MinimiseIcon = styled.span.withConfig({
  displayName: "Icons__MinimiseIcon",
  componentId: "sc-468fb-0"
})(["", ";margin-left:auto;"], iconMixin);
const EnlargeIcon = styled.span.withConfig({
  displayName: "Icons__EnlargeIcon",
  componentId: "sc-468fb-1"
})(["", ";margin-right:", ";"], iconMixin, ENLARGE_ICON_SPACING);
export { MinimiseIcon, EnlargeIcon };