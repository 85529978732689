import http from 'hub-http/clients/apiClient';
export function saveCampaign({
  objectType,
  crmIdProperty,
  selectedAssetCrmId,
  currentCampaignGuid,
  updatedCampaignGuid,
  onSuccess = () => {},
  onFailure = () => {}
}) {
  let searchRequest = Promise.resolve(selectedAssetCrmId);
  if (crmIdProperty) {
    searchRequest = http.post(`/crm-search/search`, {
      data: {
        count: 1,
        offset: 0,
        objectTypeId: objectType,
        requestOptions: {
          allPropertiesFetchMode: 'latest_version',
          includeAllProperties: true
        },
        filterGroups: [{
          filters: [{
            operator: 'EQ',
            property: crmIdProperty,
            value: selectedAssetCrmId
          }]
        }]
      }
    }).then(({
      results
    }) => results[0].objectId);
  }
  return searchRequest.then(assetObjectId => {
    // Remove existing campaign on any change (removal or select new campaign).
    const deletePromise = currentCampaignGuid ? http.delete('/campaigns/associations/assets/v2/delete', {
      data: {
        campaignGuids: [currentCampaignGuid],
        assetObjectType: objectType,
        crmAssetIds: [assetObjectId]
      }
    }) : Promise.resolve();

    // On new campaign selected, associated it.
    if (updatedCampaignGuid !== '') {
      return deletePromise.then(() => http.put('/campaigns/associations/assets/v2/create', {
        data: {
          campaignGuids: [updatedCampaignGuid],
          assetObjectType: objectType,
          crmAssetIds: [assetObjectId]
        }
      })).then(onSuccess).catch(onFailure);
    }
    return deletePromise.then(onSuccess).catch(onFailure);
  });
}
export default saveCampaign;