import styled from 'styled-components';
import { remCalc } from 'UIComponents/core/Style';
const CampaignColorDot = styled.div.withConfig({
  displayName: "CampaignColorDot",
  componentId: "sc-1k7xik5-0"
})(["border-radius:50%;height:", ";width:", ";"], ({
  size
}) => remCalc(`${size}`), ({
  size
}) => remCalc(`${size}`));
CampaignColorDot.defaultProps = {
  size: 8
};
export default CampaignColorDot;