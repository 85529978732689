import { useRef, useCallback } from 'react';
import { useCollaborationSession } from 'copilot-toolkit/hooks/useCollaborationSession';
import handleActionFromCopilot from './handleActionFromCopilot';
import { handleSlashOrHighlightCommand } from './handleSlashOrHighlightCommand';
const EDITOR_PLUGIN_ARRAY = ['EditorPlugin'];
function useSessionHelper(appKey, sessionId, customHandleTextReplacement) {
  const currentContextRef = useRef(null);
  const handleGetCurrentContext = useCallback(() => {
    return currentContextRef.current;
  }, []);
  const handleActionFromCopilotWithProp = useCallback(action => {
    return handleActionFromCopilot(action, customHandleTextReplacement);
  }, [customHandleTextReplacement]);
  const {
    sendActionFromAppToCopilot,
    sendCollaborationStateChangeFromAppToCopilot
  } = useCollaborationSession({
    onCopilotAction: handleActionFromCopilotWithProp,
    onGetCurrentSessionContext: handleGetCurrentContext,
    sessionId,
    appName: appKey,
    plugins: EDITOR_PLUGIN_ARRAY
  });
  return {
    currentContextRef,
    sendActionFromAppToCopilot,
    sendCollaborationStateChangeFromAppToCopilot
  };
}
function useCreateDefaultCommandCallback(currentContextRef, sendActionFromAppToCopilot) {
  return useCallback((editor, command, commandType) => handleSlashOrHighlightCommand(editor, command, commandType, currentContextRef, sendActionFromAppToCopilot), [currentContextRef, sendActionFromAppToCopilot]);
}
function useCreateDefaultFocusCallback(sendCollaborationStateChangeFromAppToCopilot) {
  return useCallback(({
    hasFocus,
    hasSelection
  }) => sendCollaborationStateChangeFromAppToCopilot({
    hasFocus,
    hasSelection
  }), [sendCollaborationStateChangeFromAppToCopilot]);
}
export default function useTinymceCopilotCollaborationSession(appKey, sessionId, customHandleTextReplacement, useCustomCollaborationSessionLogic) {
  var _useCustomCollaborati;
  (_useCustomCollaborati = useCustomCollaborationSessionLogic) !== null && _useCustomCollaborati !== void 0 ? _useCustomCollaborati : useCustomCollaborationSessionLogic = () => {};
  const {
    currentContextRef,
    sendActionFromAppToCopilot,
    sendCollaborationStateChangeFromAppToCopilot
  } = useSessionHelper(appKey, sessionId, customHandleTextReplacement);
  useCustomCollaborationSessionLogic(sendActionFromAppToCopilot, sendCollaborationStateChangeFromAppToCopilot, currentContextRef);

  // Will we ever need to customize the command handler or focus handler in the parent app?
  // Assuming no for now
  const commandHandler = useCreateDefaultCommandCallback(currentContextRef, sendActionFromAppToCopilot);
  const focusHandler = useCreateDefaultFocusCallback(sendCollaborationStateChangeFromAppToCopilot);
  return {
    currentContextRef,
    sendActionFromAppToCopilot,
    sendCollaborationStateChangeFromAppToCopilot,
    commandHandler,
    focusHandler
  };
}