export const CONTENT_PLATFORM_PROPERTY_NAME = Object.freeze({
  // TODO: These are all the CRM schema names for now, but will be replaced with
  // the internal property names defined by the CMP backend service

  // CMP property names defined by BE
  // https://git.hubteam.com/HubSpot/ContentManagement/blob/master/CMPCore/src/main/java/com/hubspot/cmp/core/CMPObjectFields.java
  ABSOLUTE_URL: 'hs_absolute_url',
  ANALYTICS_PAGE_ID: 'hs_analytics_page_id',
  APPROVAL_PUBLISH_STATUS: 'hs_approval_publish_status',
  ARCHIVED: 'hs_archived',
  ARCHIVED_AT: 'hs_archived_at',
  AUDIENCE_ACCESS: 'hs_audience_access',
  BUSINESS_UNIT: 'hs_all_assigned_business_unit_ids',
  CAMPAIGN: 'hs_campaign',
  CATEGORY_ID: 'hs_category_id',
  CONTENT_TYPE_CATEGORY_ID: 'hs_content_type_category_id',
  CREATED_AT: 'hs_created_at',
  CREATED_BY_USER_ID: 'hs_created_by_user_id',
  DOMAIN: 'hs_domain',
  GROUP_ID: 'hs_group_id',
  HTML_TITLE: 'hs_html_title',
  ID: 'hs_id',
  LANGUAGE: 'hs_language',
  NAME: 'hs_name',
  PUBLISHED_AT: 'hs_published_at',
  PUBLISHED_DATE: 'hs_published_date',
  RESOLVED_DOMAIN: 'hs_resolved_domain',
  RESOLVED_LANGUAGE: 'hs_resolved_language',
  SLUG: 'hs_slug',
  STATE: 'hs_state',
  TEMPLATE_PATH: 'hs_template_path',
  TRANSLATED_FROM_ID: 'hs_translated_from_id',
  UPDATED_AT: 'hs_updated_at',
  UPDATED_BY_USER_ID: 'hs_updated_by_user_id',
  VISIBLE_TO_ALL: 'hs_visible_to_all',
  CRM_OBJECT_ID: 'hs_crm_object_id',
  // properties added to objects by Content service
  CLONED_FROM: 'hs_cloned_from',
  CURRENT_STATE: 'hs_current_state',
  DELETED_AT: 'hs_deleted_at',
  PREVIEW_KEY: 'hs_preview_key',
  PARENT_BLOG: 'hs_parent_blog',
  SECURITY_STATE: 'hs_security_state',
  SUBCATEGORY: 'hs_subcategory',
  // New CRM properties
  ARCHIVED_IN_DASHBOARD: 'hs_archived_in_dashboard',
  TITLE: 'hs_title',
  AUDIENCE_ACCESS_RULE_TYPE: 'hs_access_rule_type',
  AUDIENCE_ACCESS_RULE_IDS: 'hs_access_rule_ids',
  OBJECT_TYPE: 'hs_object_type',
  CONTENT_GROUP_NAME: 'hs_content_group_name',
  SCHEDULED_PUBLISH_DATE: 'hs_scheduled_publish_date',
  SCHEDULED_UNPUBLISH_DATE: 'hs_scheduled_unpublish_date',
  TEAM_PERMS: 'hs_all_team_ids',
  USER_PERMS: 'hs_user_ids_of_all_owners',
  RESOLVED_STATE: 'hs_resolved_state'
});
export const CONTENT_PLATFORM_PROPERTY_NAMES = Object.values(CONTENT_PLATFORM_PROPERTY_NAME);